'use client';

import {useState} from 'react';
import {m as motion} from 'motion/react';
import type {MyList} from 'server/apiHandlers/getMyListsHandler';
import CreateListSection from '../CreateListSection';
import {addLine, checkLine} from 'src/utils/icons';
import ResponsiveModal from '../ResponsiveModal';
import useMyLists from 'src/hooks/useMyLists';
import pluralize from 'src/utils/pluralize';
import BaseButton from '../BaseButton';
import RemixIcon from '../RemixIcon';

export type SavePlaceModalProps = {
  children: React.ReactNode;
  initialMyLists?: MyList[];
  place: Partial<Place> | PlacePageType;
  session: IronSession | null;
};

const SavePlaceModal = ({children, initialMyLists, place, session}: SavePlaceModalProps) => {
  const [creatingNewList, setCreatingNewList] = useState(false);
  const {myLists, addPlaceToList} = useMyLists({initialMyLists, session});

  const reset = () => setCreatingNewList(false);

  return (
    <ResponsiveModal
      trigger={children}
      title={creatingNewList ? 'Create new list' : `Save ${place.name} to a list`}
      onClose={reset}
    >
      {creatingNewList ? (
        <CreateListSection onClose={() => setCreatingNewList(false)} />
      ) : (
        <motion.div layout className="flex flex-col mt-8">
          {myLists.map((list) => {
            if (list.nameId === 'visited-places') return null;

            const isSelected = list.places.some((p) => p === place._id);

            return (
              <div
                key={list._id}
                className="flex items-center gap-4 mb-5 cursor-pointer active:brightness-90"
                onClick={() => addPlaceToList(place._id, list.nameId, !isSelected)}
              >
                <BaseButton
                  onPress={() => addPlaceToList(place._id, list.nameId, !isSelected)}
                  buttonVariant={isSelected ? 'primary' : 'secondary'}
                  className="w-10 h-10 p-0 rounded-full shrink-0 flex justify-center items-center"
                >
                  <RemixIcon icon={checkLine} color={isSelected ? 'white' : 'var(--gray-text)'} />
                </BaseButton>

                <div className="flex flex-col">
                  <span className="font-semibold text-lg leading-tight">{list.name}</span>
                  <span className="text-sm text-(--gray-text)">{`${list.places.length} ${pluralize(
                    'place',
                    list.places.length
                  )}`}</span>
                </div>
              </div>
            );
          })}

          <div className="flex items-center justify-center py-2 rounded-full text-(--gray-text) text-sm">
            Rate places to mark them Visited.
          </div>

          <BaseButton
            onPress={() => setCreatingNewList(true)}
            label="Create new List"
            buttonVariant="secondary"
            className="mt-2"
            icon={addLine}
          />
        </motion.div>
      )}
    </ResponsiveModal>
  );
};

export default SavePlaceModal;
