'use client';

import {useState} from 'react';
import {useRouter} from 'next/navigation';
import {useMutation} from '@tanstack/react-query';
import {checkboxBlankCircleLine, checkboxCircleLine, settingsLine} from 'src/utils/icons';
import type {DropdownMenuItem} from './Dropdown';
import PlaceMoreButton from './PlaceMoreButton';
import fetcher from 'src/utils/fetcher';

const getAdminContextMenuItems = (
  place: Partial<PlacePageType>,
  needsWork: boolean,
  toggleNeedsWork: () => void
): DropdownMenuItem[] => [
  {
    icon: settingsLine,
    text: 'Edit Place',
    href: `/admin/places/${place._id}`
  },
  {
    icon: needsWork ? checkboxCircleLine : checkboxBlankCircleLine,
    text: 'Needs Work',
    onAction: toggleNeedsWork,
    keepOpened: true
  }
];

type Props = {
  place: Partial<PlacePageType>;
  buttonVariant?: 'primary' | 'secondary' | 'text' | 'translucent';
  className?: string;
};

const PlaceAdminMoreButton = ({place, buttonVariant, className}: Props) => {
  const router = useRouter();
  const [localNeedsWork, setLocalNeedsWork] = useState(place.needsWork || false);

  const {mutateAsync: toggleNeedsWork} = useMutation({
    mutationFn: () => {
      setLocalNeedsWork(!localNeedsWork);

      return fetcher({
        url: `/api/places/${place._id}`,
        options: {method: 'PUT'},
        body: {needsWork: !localNeedsWork}
      });
    },
    onSuccess: () => router.refresh()
  });

  const adminMenuItems = getAdminContextMenuItems(place, localNeedsWork, toggleNeedsWork);

  return <PlaceMoreButton extraMenuItems={adminMenuItems} buttonVariant={buttonVariant} className={className} />;
};

export default PlaceAdminMoreButton;
