'use client';

import seedrandom from 'seedrandom';
import {twJoin, twMerge} from 'tailwind-merge';
import {useSetGalleryImageId} from 'src/hooks/useGalleryImage';
import getImagesRowSizes from 'src/utils/getImagesRowSizes';
import getImageAltText from 'src/utils/getImageAltText';
import getImageUrl from 'src/utils/getImageUrl';
import BaseButton from '../BaseButton';
import FastImage from '../FastImage';

type Props = {
  images: Partial<PlaceImage>[];
  placeName: string;
  countryName: string;
  className?: string;
  imagesVariant?: 'thumb' | 'large';
  maxRotation?: number;
  borderRadius?: 'small' | 'large';
  replace?: boolean;
  priority?: boolean;
};

const ImageDeck = ({
  images,
  placeName,
  countryName,
  className,
  imagesVariant = 'thumb',
  maxRotation = 5,
  borderRadius = 'small',
  replace,
  priority
}: Props) => {
  const setActiveImage = useSetGalleryImageId(replace);
  const imageSizes = getImagesRowSizes(images, 100);

  const radiusClassName = borderRadius === 'small' ? 'rounded-2xl' : 'rounded-3xl';

  return (
    <div
      className={twMerge(
        'flex items-end justify-center h-[240px] relative mx-auto',
        images.length >= 2 ? 'w-full' : 'w-[60%]',
        className
      )}
    >
      {images.map((image, index) => {
        const randomNumber = seedrandom(image.path)();
        const rotation = randomNumber * (maxRotation * 2) - maxRotation;
        const imageSize = imageSizes[index];

        return (
          <div
            key={image._id}
            className="relative -mr-2 h-full"
            style={{
              width: `${imageSize.widthPercentage}%`,
              transform: `rotate(${rotation}deg)`
            }}
          >
            <BaseButton
              onPress={() => setActiveImage(image._id)}
              className={twJoin(
                'block relative overflow-visible w-full h-full p-0 focus-visible:ring-offset-1 focus-visible:ring-2 focus:ring-gray-200 hover:scale-[1.02] transition-transform duration-200 data-[pressed]:scale-[0.98]',
                radiusClassName
              )}
            >
              <div
                className={twJoin(
                  'absolute top-px right-px bottom-px left-px [box-shadow:var(--attraction-shadow)] dark:shadow-none',
                  radiusClassName
                )}
              />

              <div
                className={twJoin(
                  'nice-image absolute w-full h-full top-0',
                  radiusClassName,
                  borderRadius === 'small' ? 'after:rounded-2xl' : 'after:rounded-3xl'
                )}
              >
                <FastImage
                  priority={priority}
                  src={getImageUrl(image.path, imagesVariant === 'thumb' ? 'thumb' : '')}
                  alt={getImageAltText(image, placeName, countryName)}
                  fill
                  sizes="(max-width: 800px) 40vw, 280px"
                  className="object-cover w-full h-full"
                />
              </div>
            </BaseButton>
          </div>
        );
      })}
    </div>
  );
};

export default ImageDeck;
