import {twMerge} from 'tailwind-merge';
import BaseButton from './BaseButton';
import Avatar from './Avatar';

type Props = {
  user: User | IronSession;
  avatarSize?: number;
  sublabel?: string;
  className?: string;
  href?: string;
};

const UserTag = ({user, avatarSize, sublabel, className, href}: Props) => {
  return (
    <BaseButton
      buttonVariant="text"
      className={twMerge('rounded-full px-1 py-1 pr-3 h-auto shrink', className)}
      href={href || `/${user.nameId}/lists`}
      prefetch={false}
    >
      <Avatar user={user} size={avatarSize} className="mr-3" />

      <div className="flex flex-col items-start mr-1 shrink grow-0 truncate">
        <span className="text-bold text-current truncate w-full">{user.name}</span>
        {sublabel ? <span className="text-xs text-(--gray-text)">{sublabel}</span> : null}
      </div>
    </BaseButton>
  );
};

export default UserTag;
