import {twMerge} from 'tailwind-merge';
import getImageUrl from 'src/utils/getImageUrl';

type Props = {
  user: User | IronSession;
  style?: React.CSSProperties;
  className?: string;
  largeImage?: boolean;
  size?: number;
};

const Avatar = ({user, style, className, largeImage, size = 36}: Props) => {
  const suffix = largeImage ? 'large' : '';
  const backgroundImage = user.avatarPath ? `url(${getImageUrl(user.avatarPath, suffix)})` : undefined;

  return (
    <div
      className={twMerge(
        'relative btn-border rounded-full bg-(--page-background) text-(--dynamic-purple) text-xl bg-cover shrink-0',
        className
      )}
      style={{...style, width: size, height: size, backgroundImage}}
    >
      {user.avatarPath ? null : (
        <span className="flex w-full h-full justify-center items-center font-bold">{user.name?.[0]}</span>
      )}
    </div>
  );
};

export default Avatar;
