'use client';

import {lazy, memo} from 'react';
import {twMerge} from 'tailwind-merge';
import MapAttribution from '../map/MapAttribution';
import useDarkMode from 'src/hooks/useDarkMode';
import useMounted from 'src/hooks/useMounted';
import {earthLine} from 'src/utils/icons';
import BaseButton from '../BaseButton';

const StaticMap = lazy(() => import('../map/StaticMap'));

// const size = 760;

// const getStaticUrl = (isDark: boolean, place: Partial<PlacePageType>, zoomedOut?: boolean) => {
//   const theme = isDark ? 'dark' : 'light';
//   const zoomQueryString = zoomedOut ? '&zoom=5' : '';

//   return `${process.env.NEXT_PUBLIC_STATIC_MAP_BASE_URL}/api/static-maps/place?selectedPlace=${place.nameId}_${place.countryCode}&width=${size}&height=${size}&theme=${theme}${zoomQueryString}`;
// };

type Props = {
  place: Partial<PlacePageType>;
  className?: string;
  zoomedOut?: boolean;
};

const MapImage = ({place, className, zoomedOut}: Props) => {
  const isMounted = useMounted();
  const {resolvedTheme} = useDarkMode();

  const mapUrl = `/map/?selectedPlace=${place.nameId}_${place.countryCode}`;

  return (
    <div className={twMerge('relative pb-[50%] max-[800px]:pb-[300px] h-0', className)}>
      <div className="p-0 absolute w-full h-full">
        <div className="absolute bottom-2 h-[85%] w-[70%] left-[15%] dark:hidden shadow-[0_30px_70px_0_rgba(0,0,0,0.1),0_30px_60px_0_rgba(213,241,209,0.5)]" />

        <div className="relative flex p-3 gap-3 w-full h-full rounded-3xl overflow-hidden bg-[#f5fcf3] dark:bg-[#1b1b1b] shadow-[0_20px_50px_0_rgba(0,0,0,0.05)]">
          {isMounted ? (
            <div className="p-0 nice-image absolute flex items-center justify-center left-0 top-0 w-full rounded-3xl h-full after:rounded-3xl">
              <StaticMap places={[place]} theme={resolvedTheme} zoom={zoomedOut ? 5 : undefined} />

              {/* <FastImage
                src={getStaticUrl(isDarkTheme, place, zoomedOut)}
                width={760}
                height={760}
                sizes="(max-width: 1000px) 100vw, 50vw"
                className="w-[760px] h-[760px] object-cover"
                alt={`Map of ${place.name}`}
              /> */}
            </div>
          ) : null}

          <BaseButton
            icon={earthLine}
            color="var(--text-color)"
            buttonVariant="translucent"
            className="relative"
            prefetch={false}
            href={mapUrl}
            label="Map page"
          />

          <MapAttribution />
        </div>
      </div>
    </div>
  );
};

export default memo(MapImage, (prev, next) => prev.place._id === next.place._id);
