'use client';

import {Fragment} from 'react';
import {twMerge} from 'tailwind-merge';
import {getShadowColorFromBlur} from 'src/utils/getColorFromBlur';
import {useSetGalleryImageId} from 'src/hooks/useGalleryImage';
import getImageAltText from 'src/utils/getImageAltText';
import getImageUrl from 'src/utils/getImageUrl';
import AspectRatio from './common/AspectRatio';
import FadingImage from './FadingImage';
import BaseButton from './BaseButton';

type Props = {
  image: Partial<PlaceImage>;
  className?: string;
  imageClassName?: string;
  priority?: boolean;
  ratio?: number;
  getShadowString?: (shadowColor: number[]) => string;
  sizes: string;
  imageVariant: 'large' | 'thumbnail';
  placeName: string;
  countryName: string;
};

const HeroImage = ({
  image,
  className,
  imageClassName,
  priority,
  ratio,
  getShadowString,
  sizes,
  imageVariant,
  placeName,
  countryName
}: Props) => {
  const setGalleryImageId = useSetGalleryImageId();
  const shadowColor = getShadowColorFromBlur(image.blurHash);

  const Wrapper = ratio ? AspectRatio : Fragment;

  const ratioProps = ratio ? {ratio} : {};

  return (
    <Wrapper {...ratioProps}>
      <div className={className}>
        <div
          className="absolute bottom-2 h-[85%] w-[70%] left-[15%] dark:hidden"
          style={{
            boxShadow: getShadowString
              ? getShadowString(shadowColor)
              : `0 30px 70px 0 rgba(0, 0, 0, 0.2), 0 30px 60px 0 rgba(${shadowColor.join(',')},0.5)`
          }}
        />

        <BaseButton
          onPress={() => setGalleryImageId(image._id)}
          style={{backgroundColor: `rgb(${shadowColor.join(',')})`}}
          className='will-change-transform nice-image w-full h-full focus-visible:ring-offset-1 focus-visible:ring-2 focus:ring-gray-200 rounded-3xl after:rounded-3xl after:content-[""] hover:scale-[1.02] transition-transform data-[pressed]:scale-100'
        >
          <FadingImage
            className={twMerge('object-cover', imageClassName)}
            src={getImageUrl(image.path, imageVariant === 'thumbnail' ? 'thumb' : undefined)}
            priority={priority}
            fill
            alt={getImageAltText(image, placeName, countryName)}
            sizes={sizes}
          />
        </BaseButton>
      </div>
    </Wrapper>
  );
};

export default HeroImage;
