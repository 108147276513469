'use client';

import seedrandom from 'seedrandom';
import {useQuery} from '@tanstack/react-query';
import HeroImage from '../HeroImage';

const getShadowString = (shadowColor: number[]) => {
  return `0 15px 45px 0 rgba(0, 0, 0, 0.1), 0 20px 40px 0 rgba(${shadowColor.join(',')},0.5)`;
};

type Props = {
  initialImages: Partial<PlaceImage>[];
  getImages: () => Promise<Partial<PlaceImage>[]>;
  queryKey: string[];
  placeName: string;
  countryName: string;
};

const MobileHeroImages = ({initialImages, getImages, queryKey, placeName, countryName}: Props) => {
  const {data: allImages} = useQuery<Partial<PlaceImage>[]>({
    queryKey,
    queryFn: () => getImages()
  });

  const images = allImages || initialImages || [];

  if (images.length === 0) return <div className="h-5" />;

  return (
    <div className="hidden max-[800px]:flex gap-8 h-[300px] -mb-[34px] px-(--page-padding) w-full overflow-x-auto no-scrollbar">
      {images.slice(0, 20).map((image, index) => {
        const randomNumber = seedrandom(image.path)();
        const rotation = randomNumber * 6 - 3;
        const aspectRatio = (image?.width || 1) / (image?.height || 1);
        const imageWidth = 210 * aspectRatio;

        return (
          <div
            key={image.path}
            className="shrink-0 mt-8 h-[70%]"
            style={{width: `min(${imageWidth}px, 80%)`, transform: `rotate(${rotation}deg)`}}
          >
            <HeroImage
              image={image}
              placeName={placeName}
              countryName={countryName}
              priority={index <= 1}
              className="w-full h-full"
              getShadowString={getShadowString}
              imageVariant="large"
              sizes="(max-width: 450px) 80vw, 350px"
            />
          </div>
        );
      })}
    </div>
  );
};

export default MobileHeroImages;
